.mod-links {
	&.element-template--menu, &.element-template--inline {
		ul {
			@include clear-ul;
		}
	}

	&.element-template--default, &.element-template--default-small {
		ul {
			@include clear-ul;
			margin: 0;
			padding: 0;
		}

		li {
			display: table;

			a {
				border: 1px solid #e3e3e3;
				border-radius: 5px;
				box-sizing: border-box;
				color: inherit;
				display: table-cell;
				font-weight: 700;
				line-height: 1.3;
				overflow: hidden;
				padding: .3em 2.2em .3em 1.2em;
				text-decoration: none;
				vertical-align: middle;
				width: 100%;
				transition: padding .14s;

				position: relative;

				&:before, &:after {
					background: $theme-primary-color;
					border-radius: 40px;
					content: '';
					font-size: 1em;
					width: 0.22em;
					height: 1em;
					display: block;
					transition: transform .14s, margin .14s, right .2s;
					text-align: center;
					line-height: 1;
					position: absolute;
					right: 1.2em;
					z-index: 10;
				}

				&:before {
					top: 50%;
					transform: rotate(-45deg);
					margin-top: -.8em;
				}

				&:after {
					bottom: 50%;
					margin-bottom: -.8em;
					transform: rotate(45deg);
				}

				&:active {
					&:before {
						transform: rotate(-55deg);
						margin-top: -.74em;
					}

					&:after {
						transform: rotate(55deg);
						margin-bottom: -.74em;
					}
				}
			}

			img {
				margin: -1em 1em -1em -1.2em;
				transition: margin .14s;
				vertical-align: middle;
			}

			&.type-image {
				min-width: 10em;

				a {
					display: block;
					padding: 0;

					&:before, &:after {
						content: normal;
					}
				}

				img {
					margin: 0;
					border-radius: 0;
					position: absolute;
					left: 50%;
					max-height: none;
					max-width: none;
					width: 100%;
					top: 50%;
					transform: translateY(-50%) translateX(-50%);
				}
			}
		}
	}

	&.element-template--default {
		ul {
			@include basic-columns(3, $breakpoint: phone-landscape, $selector: 'li');
		}

		li {
			a {
				height: 5em;
			}

			img {
				max-height: 5em;
				max-width: 5em;
			}
		}
	}

	&.element-template--default-small {
		li {
			display: inline-block;
			margin-right: .6em;

			a {
				height: 2.5em;
				padding-left: .8em;

				&:before, &:after {
					font-size: .7em;
				}
			}

			img {
				max-height: 2.5em;
				max-width: 2.5em;
			}

			&.type-both {
				img {
					margin-left: -1em;
					margin-right: .6em;
				}
			}

			&.type-image {
				min-width: 8em;
			}
		}
	}

	&.element-template--menu {
		border: 1px solid #e3e3e3;
		border-radius: 5px;
		overflow: hidden;

		li, a {
			display: block;
		}

		li {
			border-bottom: 1px solid #e3e3e3;

			&:last-child {
				border-bottom: none;
			}
		}

		a {
			color: inherit;
			padding: .8em 1em;
			text-decoration: none;
			transition: background .18s;

			&:hover {
				background: rgba(#000, .03);
			}
		}

		.active a {
			color: $theme-primary-color;
		}

		.boxed & {
			border: none;
			border-radius: 0;
			margin: -1.5em;

			&:not(:first-child) {
				margin-top: $element-vertical-spacing;
			}

			&:not(:last-child) {
				margin-bottom: $element-vertical-spacing;
			}

			a {
				padding: .8em 1.5em;
			}
		}
	}

	&.element-template--inline {
		ul {
			margin: -.3em 0;
		}

		li {
			display: inline-block;
			margin-left: -.32em;
			position: relative;

			&:first-child {
				margin-left: 0;
			}

			&:before, &:after {
				background: #d3d3d3;
				content: '' !important;
				display: block;
				font-size: 1.14rem;
				height: 1.2em;
				position: absolute;
				top: .4em;
				left: 0;
				width: 1px;
			}

			&:after {
				display: none;
				left: auto;
				right: 0;
			}

			&:last-child:after {
				display: block;
			}

			a {
				display: inline-block;
				padding: .3em .6em;
			}

			&.active {
				z-index: 1;

				&:before, &:after {
					display: none;
				}

				a {
					background: $theme-primary-color;
					border-radius: 3px;
					color: $inline-active-font-color;
				}
			}

			@include breakpoint(phone-portrait) {
				border: 1px solid #d3d3d3;
				border-width: 1px 0;
				display: block;
				margin: -1px 0 0;

				&:first-child {
					margin-top: 0;
				}

				a {
					display: block;
					padding: .5em 1em;
				}

				&:before, &:after {
					display: none !important;
				}
			}
		}
	}
}
